.App {
  text-align: center;
  padding-left: calc(100vw - 100%);
  
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


.br {
  display: block;
  margin-bottom: -1em;
}



.fixed-widgets {
  position: fixed;
  right: 32px;
  bottom: 45px;
  z-index: 2147483640;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.fixed-widgets-left {
  position: fixed;
  left: 32px;
  bottom: 45px;
  z-index: 2147483640;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
